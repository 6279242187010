import React from 'react'
import images from "../assets/images";
import styles from './Gallery.module.scss';
import {Card, CardContent, CardMedia, Typography} from "@material-ui/core";
import {Category} from "./sectionTypes";
import {GalleryModal} from "../components/GalleryModal";

const categories: Category[] = [
    {
        name: 'Meble kuchenne',
        description: 'Meble kuchenne stanowią bardzo ważny element każdej kuchni.\n' +
            'Salon meblowy w Krobi ma do zaoferowania w tym asortymencie sporo różnorakich modeli, dlatego też zapewne każdy znajdzie coś odpowiedniego dla siebie. Nasze meble są wykonane z wysokiej jakości materiału i co najważniejsze po rozsądnych cenach co sprawia, iż każdy może sobie na nie pozwolić.',
        coverImage: images.cover.kuchnia,
        images: images.kuchnia.map(source => ({source}))
    },
    {
        name: 'Meble systemowe',
        description: 'Meble pokojowe i systemowe to doskonały sposób na stworzenie jednolitego, eleganckiego klimatu w dowolnym domu bądź mieszkaniu. Posiadając bardzo rozbudowane możliwości wyboru urozmaiconych propozycji potencjał aranżacyjny dowolnej, nawet niewielkiej przestrzeni jest niezwykle duży.',
        coverImage: images.cover.systemowe,
        images: images.systemowe.map(source => ({source}))
    },
    {
        name: 'Sypialnie',
        description: 'Wyróżnikiem najlepszych mebli do sypialni jest umiejętna kompozycja łącząca w sobie walory estetyczne, funkcjonalne oraz doskonałą wygodę odpoczynku. Przygotowując naszą ofertę kierowaliśmy się właśnie tymi aspektami, czego rezultatem jest prezentowany wybór rozwiązań ciekawych, pięknych oraz bardzo komfortowych. ',
        coverImage: images.cover.sypialnie,
        images: images.sypialnie.map(source => ({source}))
    },
    {
        name: 'Stoły i krzesła',
        description: 'Posiadamy w swojej ofercie bogaty wybór stołów i krzeseł. Zapraszamy do obejrzenia mebli na żywo w naszym sklepie stacjonarnym. Polecamy Państwu nasze zestawy zawierające stoły i krzesła wykonane z okleiny naturalnej.',
        coverImage: images.cover.stoly,
        images: images.stoly.map(source => ({source}))
    },
    {
        name: 'Meblościanki',
        description: 'Meblościanki to idealne rozwiązanie do zaaranżowania wnętrz o niewielkiej powierzchni. Prezentujemy Państwu naszą rozbudowaną ofertę na nowoczesne meblościanki. Wyszukane wzornictwo, ekstrawagancki styl – to cechy jakimi odznacza się nasz asortyment.',
        coverImage: images.cover.mebloscianki,
        images: images.mebloscianki.map(source => ({source}))
    },
    {
        name: 'Meble tapicerowane',
        description: 'Wszyscy pragniemy dobrze wypocząć po ciężkiej pracy w pięknym i przyjemnym mieszkaniu. Z tego powodu do naszego mieszkania dobrze jest zakupić zestaw meblowy, który można dowolnie ze sobą zestawiać.',
        coverImage: images.cover.tapicerowane,
        images: images.tapicerowane.map(source => ({source}))
    },
    {
        name: 'Meble młodzieżowe',
        description: 'Wybierając meble dla dzieci i młodzieży warto jest uwzględnić takie aspekty jak bezpieczeństwo, oszczędność przestrzeni oraz estetykę odpowiadającą gustom i charakterowi mieszkańca danego pokoju młodzieżowego.',
        coverImage: images.cover.mlodz,
        images: images.mlodz.map(source => ({source}))
    },
    {
        name: 'Szafy',
        description: 'Poza szerokim wyborem szaf w różnych rozmiarach, salon w Krobi oferuje również zabudowy wnęk na indywidualne zamówienia z pomiarem w domu klienta.',
        coverImage: images.cover.szafy,
        images: images.szafy.map(source => ({source}))
    },
]


const CategoryCard = (category: Category): React.ReactElement => {
    const [isOpen, setIsOpen] = React.useState(false)
    const [canBeOpened, setCanBeOpened] = React.useState(true)

    const onOpen = (e: any) => {
        if (canBeOpened) {
            setIsOpen(true)
        }
    }

    const handleClose = () => {
        setCanBeOpened(false)
        setIsOpen(false)
        setTimeout(() => setCanBeOpened(true), 1000)
    }

    return <Card className={styles.card} onClick={onOpen}>
        <CardMedia image={category.coverImage} title={category.name} className={styles.cardMedia}/>
        <CardContent>
            <Typography variant="h5" className={styles.categoryName}>
                {category.name}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
                {category.description}
            </Typography>
            <GalleryModal images={category.images} isOpen={isOpen} handleClose={handleClose}/>
        </CardContent>
    </Card>
}

export const GallerySection = () => {
    return <div className={styles.gallery}>
        <h3>Nasza oferta</h3>
        <div className={styles.categoriesGrid}>
            {categories.map(category => {
                return (
                    <div className={styles.gridItemContainer}>
                        {CategoryCard(category)}
                    </div>
                );
            })}
        </div>
        <h5 className={styles.disclaimer}>
            Zdjęcia dostępne w galerii pochodzą z katalogów naszych dostawców. <br/>
            Wszystkie widoczne meble znajdują się w stałej ofercie salonu meblowego w Krobi.
        </h5>
    </div>
}