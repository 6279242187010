import React from "react";

interface VerticalSpaceProps {
    height?: number;
    id?: string;
}

export const VerticalSpace: React.FC<VerticalSpaceProps> = ({
                                                                height = 10,
    id
                                                            }) => {
    const style = {
        paddingTop: height
    }
    return <div style={style} id={id ?? ''}/>
}
