import React from 'react'
import {Divider, Toolbar} from "@material-ui/core"
import styles from './Header.module.scss'
import {Link} from "react-scroll"

export type Section = {
    title: string,
    url: string
}

type HeaderProps = {
    sections: Section[]
}

export const Header = ({sections}: HeaderProps) => {
    return <>
        <Toolbar component="nav" className={styles.toolbar}>
            <div className={styles.placeholder}/>
            <div className={styles.sectionLink}>
                {sections.map((section) =>
                    <Link to={section.url} spy={true} smooth={true}>
                        <div className={styles.btnOne}>
                            <span>{section.title}</span>
                            <Divider light/>
                        </div>
                    </Link>
                )}
            </div>
            <div className={styles.logo}/>
        </Toolbar>
        <Divider/>
    </>
}
