import React from 'react'
import styles from './Contact.module.scss'
import {VerticalSpace} from "../components/VerticalSpace";
import {Paper} from "@material-ui/core";
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import PhoneIcon from '@material-ui/icons/Phone';
import MailIcon from '@material-ui/icons/Mail';
import {Map} from "../components/Map";
import {PhoneIphone} from "@material-ui/icons";


export const ContactSection = () => {

    return <div className={styles.section}>
        <div className={styles.row}>
            <div className={styles.textTile}>
                <h3>Dane kontaktowe</h3>
                <div className={styles.textTile}>
                    Nasza firma mieści się przy ulicy &nbsp;
                    <span className={styles.bold}>
                     Długiej 2 w Krobi k/ Torunia
                    </span>

                    <VerticalSpace height={16}/>

                    <Paper className={styles.map}>
                        <Map/>
                    </Paper>

                    <VerticalSpace/>

                    <div>
                        <div className={styles.subsectionHeader}>
                            <AccessTimeIcon className={styles.icon}/>
                            <h4 className={styles.hoursHeader}>
                                Godziny otwarcia
                            </h4>
                        </div>
                        <span className={styles.bold}>9:00 - 17:00</span> od poniedziałku do piątku
                        <VerticalSpace height={8}/>
                        <span className={styles.bold}>9:00 - 14:00</span> w sobotę
                    </div>

                    <VerticalSpace/>

                    <div>
                        <div className={styles.subsectionHeader}>
                            <PhoneIcon className={styles.icon}/>
                            <span>Telefon kontaktowy:</span>
                            <span className={styles.bold}>56 678 53 28</span>
                            <span> lub </span>
                            <PhoneIphone className={styles.icon}/>
                            <span className={styles.bold}>668 501 495</span>
                        </div>

                        <div className={styles.subsectionHeader}>
                            <MailIcon className={styles.icon}/>
                            <span>Adres e-mail:</span> &nbsp;
                            <span className={styles.bold}>maciejewska.lubicz@op.pl</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}