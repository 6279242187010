import React from 'react'
import styles from './App.module.scss'
import {Header} from "./components/Header";
import {Container} from "@material-ui/core";
import {HomeSection} from './sections/Home';
import {GallerySection} from "./sections/Gallery";
import {ContactSection} from "./sections/Contact";
import {Parallax} from 'react-parallax';
import images from './assets/images';
import {VerticalSpace} from "./components/VerticalSpace";

const siteSections = [
    {title: 'O nas', url: '/'},
    {title: 'Oferta', url: '/oferta'},
    {title: 'Kontakt', url: '/kontakt'},
]

function App() {
    return <>
        <div className={styles.header}>
            <Header sections={siteSections}/>
        </div>
        <Parallax bgImage={images.stock1_fhd} strength={300} className={styles.homeParallax}>
            <VerticalSpace height={40} id={siteSections[0].url}/>

            <Container className={styles.container} maxWidth="md">
                <HomeSection/>
            </Container>

            <div id={siteSections[1].url}/>
            <VerticalSpace height={40}/>
        </Parallax>

        <Parallax bgImage={images.stock3_fhd} strength={150} className={styles.contactParallax}>
            <VerticalSpace height={40} id={siteSections[0].url}/>

            <Container className={styles.container} maxWidth="lg">
                <GallerySection/>
            </Container>

            <VerticalSpace height={40} id={siteSections[0].url}/>
        </Parallax>

        <Parallax bgImage={images.stock2_fhd} strength={500} className={styles.contactParallax}>
            <VerticalSpace height={40} id={siteSections[2].url}/>

            <Container className={styles.container} maxWidth="md">
                <ContactSection/>
            </Container>

            <VerticalSpace height={200}/>
        </Parallax>
    </>
}

export default App
