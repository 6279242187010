import logo600 from './logo_600.png'
import stock1_fhd from './stock1_fhd.jpg'
import stock2_fhd from './stock2_fhd.jpg'
import stock3_fhd from './stock3_fhd.jpg'
import template from './template_category_cover.jpg'

import kuchnia11 from './resized/kuchnia11.jpg'
import kuchnia10 from './resized/kuchnia10.jpg'
import kuchnia12 from './resized/kuchnia12.jpg'
import kuchnia1 from './resized/kuchnia1.jpg'
import kuchnia13 from './resized/kuchnia13.jpg'
import kuchnia2 from './resized/kuchnia2.jpg'
import kuchnia4 from './resized/kuchnia4.jpg'
import kuchnia3 from './resized/kuchnia3.jpg'
import kuchnia5 from './resized/kuchnia5.jpg'
import kuchnia7 from './resized/kuchnia7.jpg'
import kuchnia6 from './resized/kuchnia6.jpg'
import kuchnia8 from './resized/kuchnia8.jpg'
import kuchnia9 from './resized/kuchnia9.jpg'

import mebloscianki1 from './resized/mebloscianki1.jpg'
import mebloscianki2 from './resized/mebloscianki2.jpg'
import mebloscianki4 from './resized/mebloscianki4.jpg'
import mebloscianki3 from './resized/mebloscianki3.jpg'

import mlodz10 from './resized/mlodz10.jpg'
import mlodz1 from './resized/mlodz1.jpg'
import mlodz11 from './resized/mlodz11.jpg'
import mlodz2 from './resized/mlodz2.jpg'
import mlodz4 from './resized/mlodz4.jpg'
import mlodz3 from './resized/mlodz3.jpg'
import mlodz5 from './resized/mlodz5.jpg'
import mlodz7 from './resized/mlodz7.jpg'
import mlodz6 from './resized/mlodz6.jpg'
import mlodz8 from './resized/mlodz8.jpg'
import mlodz9 from './resized/mlodz9.jpg'

import stoly1 from './resized/stoly1.jpg'
import stoly3 from './resized/stoly3.jpg'
import stoly2 from './resized/stoly2.jpg'
import stoly4 from './resized/stoly4.jpg'
import stoly5 from './resized/stoly5.jpg'

import sypialnie1 from './resized/sypialnie1.jpg'
import sypialnie2 from './resized/sypialnie2.jpg'
import sypialnie4 from './resized/sypialnie4.jpg'
import sypialnie3 from './resized/sypialnie3.jpg'
import sypialnie5 from './resized/sypialnie5.jpg'
import sypialnie6 from './resized/sypialnie6.jpg'

import systemowe1 from './resized/systemowe1.jpg'
import systemowe2 from './resized/systemowe2.jpg'
import systemowe5 from './resized/systemowe5.jpg'
import systemowe4 from './resized/systemowe4.jpg'
import systemowe6 from './resized/systemowe6.jpg'
import systemowe8 from './resized/systemowe8.jpg'
import systemowe7 from './resized/systemowe7.jpg'
import systemowe9 from './resized/systemowe9.jpg'

import szafy11 from './resized/szafy11.jpg'
import szafy10 from './resized/szafy10.jpg'
import szafy1 from './resized/szafy1.jpg'
import szafy3 from './resized/szafy3.jpg'
import szafy2 from './resized/szafy2.jpg'
import szafy4 from './resized/szafy4.jpg'
import szafy6 from './resized/szafy6.jpg'
import szafy5 from './resized/szafy5.jpg'
import szafy7 from './resized/szafy7.jpg'
import szafy9 from './resized/szafy9.jpg'
import szafy8 from './resized/szafy8.jpg'

import tapicerowane10 from './resized/tapicerowane10.jpg'
import tapicerowane12 from './resized/tapicerowane12.jpg'
import tapicerowane11 from './resized/tapicerowane11.jpg'
import tapicerowane13 from './resized/tapicerowane13.jpg'
import tapicerowane2 from './resized/tapicerowane2.jpg'
import tapicerowane1 from './resized/tapicerowane1.jpg'
import tapicerowane3 from './resized/tapicerowane3.jpg'
import tapicerowane5 from './resized/tapicerowane5.jpg'
import tapicerowane4 from './resized/tapicerowane4.jpg'
import tapicerowane6 from './resized/tapicerowane6.jpg'
import tapicerowane8 from './resized/tapicerowane8.jpg'
import tapicerowane7 from './resized/tapicerowane7.jpg'
import tapicerowane9 from './resized/tapicerowane9.jpg'

import kuchnia from './cover/kuchnia.jpg'
import mebloscianki from './cover/mebloscianki.jpg'
import mlodz from './cover/mlodz.jpg'
import stoly from './cover/stoly.jpg'
import sypialnie from './cover/sypialnie.jpg'
import systemowe from './cover/systemowe.jpg'
import szafy from './cover/szafy.jpg'
import tapicerowane from './cover/tapicerowane.jpg'


const images = {
    logo600,
    stock1_fhd,
    stock2_fhd,
    stock3_fhd,
    template,
    kuchnia: [
        kuchnia11,
        kuchnia10,
        kuchnia12,
        kuchnia1,
        kuchnia13,
        kuchnia2,
        kuchnia4,
        kuchnia3,
        kuchnia5,
        kuchnia7,
        kuchnia6,
        kuchnia8,
        kuchnia9,
    ],
    mebloscianki: [
        mebloscianki1,
        mebloscianki2,
        mebloscianki4,
        mebloscianki3,
    ],
    mlodz: [
        mlodz10,
        mlodz1,
        mlodz11,
        mlodz2,
        mlodz4,
        mlodz3,
        mlodz5,
        mlodz7,
        mlodz6,
        mlodz8,
        mlodz9,
    ],
    stoly: [
        stoly1,
        stoly3,
        stoly2,
        stoly4,
        stoly5,
    ],
    sypialnie: [
        sypialnie1,
        sypialnie2,
        sypialnie4,
        sypialnie3,
        sypialnie5,
    ],
    systemowe: [
        systemowe1,
        sypialnie6,
        systemowe2,
        systemowe5,
        systemowe4,
        systemowe6,
        systemowe8,
        systemowe7,
        systemowe9,
    ],
    szafy: [
        szafy11,
        szafy10,
        szafy1,
        szafy3,
        szafy2,
        szafy4,
        szafy6,
        szafy5,
        szafy7,
        szafy9,
        szafy8,
    ],
    tapicerowane: [
        tapicerowane10,
        tapicerowane12,
        tapicerowane11,
        tapicerowane13,
        tapicerowane2,
        tapicerowane1,
        tapicerowane3,
        tapicerowane5,
        tapicerowane4,
        tapicerowane6,
        tapicerowane8,
        tapicerowane7,
        tapicerowane9,
    ],

    cover: {
        kuchnia,
        mebloscianki,
        mlodz,
        stoly,
        sypialnie,
        systemowe,
        szafy,
        tapicerowane,
    }
}

export default images