import {List, ListItem, ListItemIcon} from '@material-ui/core'
import {Link as ScrollLink} from "react-scroll"
import React from 'react'
import images from '../assets/images'
import styles from './Home.module.scss'
import {VerticalSpace} from "../components/VerticalSpace";
import {ChevronRight} from "@material-ui/icons";

const asortymentItems = ['meblościanek',
    'stołów i krzeseł',
    'kompletów wypoczynkowych',
    'sypialni',
    'mebli dziecięcych',
    'mebli kuchennych',
    'zabudowy wnęk również na indywidualne zamówienia z  pomiarem w domu klienta',]

const CustomList = (items: string[]): React.ReactElement => {
    return (
        <List>
            {items.map(item => <ListItem className={styles.listItem}>
                <ListItemIcon>
                    <ChevronRight/>
                </ListItemIcon>
                {item}
            </ListItem>)}
        </List>
    )
}

export const HomeSection = () => {
    return <div className={styles.page}>
        <div className={styles.firstRow}>
            <div className={styles.textTile}>
                <h3>O nas</h3>

                <div className={styles.textTile}>
                    Jesteśmy rodzinną firmą działającą na rynku od 1998 roku.
                    Naszą misją jest sprostanie wymaganiom naszych klientów i pomoc w wyborze mebli, które będą służyć
                    latami.
                    <VerticalSpace/>
                    {/*W naszym salonie uzyskasz fachową poradę, pomożemy Ci dobrać tapicerkę na krzesła w kolorze wybranego zestawu wypoczynkowego.*/}
                    {/*<VerticalSpace/>*/}
                    Gwarantujemy szybkie terminy realizacji zamówień, posiadamy własny transport, meble do domu klienta
                    dostarczamy gratis.
                    Oferujemy korzystny system ratalny. Staramy się aby każdy klient odwiedzający nas salon wychodził
                    zadowolony i chętnie powracał.
                    <VerticalSpace/>
                    Urządzasz dom lub mieszkanie? Koniecznie odwiedź Salon Meblowy w Krobi!
                </div>
            </div>
        </div>

        <VerticalSpace/>

        <div className={styles.row}>
            <div className={styles.imageTile}>
                <img alt='logo' src={images.logo600}/>
            </div>
            <div className={styles.textTile}>
                <div className={styles.textTile}>
                    Oferujemy bogaty asortyment:

                    {CustomList(asortymentItems)}
                </div>

            </div>
        </div>

        <div className={styles.bottomRow}>
            <div className={styles.textTile}>
                Więcej informacji znajdą Państwo w sekcji&nbsp;
                <ScrollLink to={'/kontakt'} spy={true} smooth={true} className={styles.sectionLink}>
                    Kontakt
                </ScrollLink>
                <VerticalSpace/>

                Zapraszamy również do obejrzenia części naszej oferty w sekcji&nbsp;
                <ScrollLink to={'/oferta'} spy={true} smooth={true} className={styles.sectionLink}>
                    Oferta
                </ScrollLink>
            </div>
        </div>


    </div>
}