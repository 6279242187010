import React from "react";
import {Image} from "../sections/sectionTypes";
import {Dialog, DialogContent} from "@material-ui/core";

import Carousel, {CarouselState, CarouselStyles, Formatters} from "react-images";

interface GalleryModalProps {
    images: Image[]
    isOpen: boolean
    handleClose: () => void
}


export const GalleryModal: React.FC<GalleryModalProps> = ({images, isOpen, handleClose}) => {
    const carouselStyles: CarouselStyles = {
        navigationNext(base: React.CSSProperties, state: CarouselState): React.CSSProperties {
            return {...base, background: "lightgray", borderWidth: "3px", borderColor: "white", border: "solid"}
        },

        navigationPrev(base: React.CSSProperties, state: CarouselState): React.CSSProperties {
            return {...base, background: "lightgray", borderWidth: "3px", borderColor: "white", border: "solid"}
        }
    }

    const carouselFormatters: Formatters = {
        getAltText: props => 'Nie udało się wczytać zdjęcia, spróbuj ponownie',
        getNextTitle: props => 'Następne',
        getPrevTitle: props => 'Poprzednie',
    }

    return <Dialog open={isOpen} onClose={handleClose}>
        <DialogContent>
            <Carousel views={images} styles={carouselStyles} formatters={carouselFormatters}/>
        </DialogContent>
    </Dialog>
}
